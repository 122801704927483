import { isAbortError } from "@dpdgroupuk/fetch-client";

import {
  FAILED_TO_FETCH,
  FAILED_TO_FETCH_ERROR_MESSAGE_$,
  SOMETHING_WENT_WRONG,
} from "~/constants/strings";

import { formatMessage } from "./string";

export const getErrorMessage = (e, failedToFetchEntityName) => {
  if (e.errors && e.errors[0] && e.errors[0].message) {
    return e.errors[0].message;
  }

  if (e.errors && e.errors.message) {
    return e.errors.message;
  }

  if (e.message) {
    if (e.message && e.message.includes(FAILED_TO_FETCH)) {
      debugger;
      return formatMessage(
        FAILED_TO_FETCH_ERROR_MESSAGE_$(failedToFetchEntityName),
        failedToFetchEntityName
      );
    }

    return e.message;
  }

  return SOMETHING_WENT_WRONG;
};

export const isUnauthorizedUserError = err => err.statusCode === 401;

export const isIgnoredError = err =>
  isAbortError(err) || isUnauthorizedUserError(err);
